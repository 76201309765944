* {
    outline: 0 !important;
    text-decoration: none !important;
}

body {
    font-family: 'Harmonia Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 30px;
    background-color: #000;
    line-height: 120%;
}

p {
    font-weight: 400;
    font-size: 30px;
    color: #fff;
    margin-bottom: 16px;
    letter-spacing: -1.2px
}

img {
    max-width: 100%;
    height: auto;
}

a,
a:hover {
    transition: 0.3s all;
}

ul,
li {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0;
    font-family: 'Harmonia Sans Pro', sans-serif;
    font-weight: 400;
    color: #fff;
    line-height: 120%;
}

h1,
.h1 {
    font-size: 72px;
}

h2,
.h2 {
    font-size: 60px;
}

h3,
.h3 {
    font-size: 40px;

}

.wrapper {
    overflow: hidden;
    position: relative;
}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 23px;
}

.text_white {
    color: #fff;
}

.sub_heading {
    font-size: 28px;
    font-weight: 700;

    letter-spacing: -1.12px;
}

.heading {
    /* 72px */
    letter-spacing: -2.88px;
}

.section-heading {
    margin-bottom: 40px;
}

/* HEADER */

header {
    padding: 20px 0;
}

.header-menu a {
    margin-left: 15px
}

.header-menu a:first-child {
    margin-left: 0
}

header .logo img {
    width: 180px;
}

header a.link {
    font-size: 20px;

    color: #fff;
    letter-spacing: 0px;
    position: relative;
    z-index: 1
}

header a.link:hover {
    color: #fff;
}

header a.link:after {
    content: "";
    background: #fff;
    width: 0%;
    right: 0;
    left: auto;
    bottom: 0;
    position: absolute;
    height: 1px;
    transition: .4s;
}

header a.link:hover:after {
    width: 100%;
    transition: .4s;
    right: auto;
    left: 0;
}


/*Canvas*/

canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
}

/*Footer*/
footer {
    padding-top: 48px;
    padding-bottom: 96px;
}

footer .flogo {
    display: inline-block;
    width: 240px;
    margin: 0 auto 48px;
    display: block;
}

footer .social {
    display: block;
    width: 100%;
    text-align: center;
    margin: 48px 0;
}

footer .social a {
    margin: 0 13px;
}

footer .social a svg {
    transform: scale(1);
    transition: 0.3s all;
}

footer .social a:hover svg {
    transform: scale(0.9);
}

footer p {
    font-size: 18px;

    letter-spacing: 0.01em;
}

footer p a {
    color: #FF8585;
    position: relative;
}

footer p a:after {
    content: "";
    background: #FF8585;
    width: 0%;
    right: 0;
    left: auto;
    bottom: -1px;
    position: absolute;
    height: 1px;
    transition: .4s;
}

footer p a:hover:after {
    width: 100%;
    transition: .4s;
    right: auto;
    left: 0;
}

footer p span {
    display: block;
    color: #808080;
    margin-top: 10px;
}

footer p a:hover {
    color: #FF8585;
}

/*News */

.news-area {
    padding-top: 63px;
    padding-bottom: 80px
}

.news-area .head {
    margin-bottom: 20px
}

.news-area .head .h2 {
    text-shadow: 0px 11px 15px rgba(0, 0, 0, 0.20), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12);
    font-weight: 700;
    background: linear-gradient(180deg, #FF8585 0%, #F39C97 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.news-list {
    padding-top: 40px
}

.news-box {
    display: block;
    border-radius: 40px;
    border: 1px solid #F39C97;
    background: linear-gradient(0deg, #000 0%, #000 100%), linear-gradient(180deg, #A35C9F 0%, #F39C97 100%), #262626;
    position: relative;
    margin-top: 40px;
    overflow: hidden;
}

.news-box .hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 3;
}

.news-box:hover .hover,
.news-box.active .hover {
    opacity: 1;
    visibility: visible
}

.news-box .image {
    width: 100%;
    height: 350px
}

.news-box .image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px
}

.news-box .text {
    padding: 20px
}

.news-box .date {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 10px
}

.news-box .title {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    padding-right: 10px
}

.news-box .text .inner {
    height: 178px;
    margin-bottom: 10px
}

.news-area .row [class*="col-sm"] {
    padding: 0 20px;
}

.news-box .action {
    color: #fafafa;
    font-size: 21.118px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.845px;
}

.news-box .action img {
    margin-left: 12px
}

.news-box .hover .title {
    font-size: 32px;
    line-height: 42px
}

.news-box .hover .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.news-box .hover .inner {
    height: auto
}

/*News Single*/
.news-content-area {
    padding-top: 60px;
    padding-bottom: 40px
}

.back_btn {
    color: #FAFAFA;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.88px;
    position: relative
}

.back_btn:hover {
    color: #fafafa
}

.back_btn span {
    position: relative
}

.back_btn span::after {
    content: "";
    background: #fff;
    width: 0%;
    right: auto;
    left: 0;
    bottom: 0;
    position: absolute;
    height: 1px;
    transition: .4s;
}

.back_btn:hover span:after {
    width: 100%;
    transition: .4s;
    right: 0;
    left: auto;
}

.news-content-area .h1 {
    font-size: 58px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -2.32px;
    background: linear-gradient(180deg, #FF8585 0%, #F39C97 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.news-content-area .content-text {
    padding-right: 10px
}

.news-content-area .content-img {
    padding-left: 40px
}

.back_action {
    margin-bottom: 20px
}

.news-content-area .date {
    color: #FFF;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.88px;
}

.news-content p {
    color: #F5ECF4;
    font-weight: 600;
    line-height: 142%;
    margin-bottom: 40px
}

.news-content p:last-child {
    margin-bottom: 0
}

.news-content strong {
    font-weight: 700
}

.news-content p a {
    color: #FF8585;
    text-decoration: underline !important;
}

.news-content .quotes {
    background: linear-gradient(180deg, #FF8585 0%, #f73772 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 65px;
    line-height: 76.5px;
    letter-spacing: -2.6px;
    margin: 80px 0
}

.news-content p a.gray_link {
    color: #808080;
}

.news-content p a.gray_link:hover {
    color: #fff
}

.news-content .h3 {
    color: #FF8585;
    font-size: 40px;
    font-weight: 600;
    margin: 30px 0
}

.news-content .h4 {
    font-weight: 600;
    font-size: 28px
}

.news-content {
    padding-top: 80px
}
.header-menu ul {
    list-style: none
}

.header-menu li {
    display: inline-block;
    margin-left: 15px
}

.header-menu li:first-child {
    margin-left: 0
}
.header-toggle {
    display: none;
}

.header-toggle {
    height: 46px;
    width: 46px;
    text-align: center;
    border-radius: 10px;
    margin-left: 10px;
}

.header-toggle span {
    height: 2px;
    width: 95%;
    background: #fff;
    display: block;
    margin-top: 5px;
    position: relative;
    transition: all 0.2s ease 0s;
    border-radius: 5px;
    margin-right: auto;
    margin-left: 0;
}

.header-toggle span:nth-child(1) {
    top: 4px;
}

.header-toggle span:nth-child(2) {
    top: 6px;
    width: 65%;
}

.header-toggle span:nth-child(3) {
    top: 8px;
}

#menu-toggle {
    width: 42px;
    height: 42px;
    display: block;
    position: relative;
    background: transparent;
    padding: 4px 8px;
    border-radius: 7px;
    margin-left: auto;
    margin-right: auto;
}


/*Responsive*/

@media(max-width:1200px) {
    .news-box .text .inner {
        margin-bottom: 20px;
    }

    .news-box .hover .title {
        font-size: 26px;
        line-height: 36px;
    }
    .news-content-area .h1{
        font-size: 50px;
        margin-bottom: 20px
    }
}

@media(max-width:991px) {
    .container {
        padding: 0 15px;
    }



    body,
    p {
        font-size: 20px;

    }

    h1,
    .h1 {
        font-size: 36px;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    .cbtn {
        font-size: 20px;
    }

    header {
        padding: 15px 0;
    }



    .heading {
        letter-spacing: -1.68px;
    }

    .sub_heading {
        font-size: 20px;
        letter-spacing: -0.88px;
        margin-bottom: 10px;
    }


    h3,
    .h3 {
        font-size: 30px;
    }


    footer {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    footer .flogo {
        width: 165px;
        margin: 0 auto 30px;
    }

    footer .social a svg {
        width: 30px;
    }

    footer .social a {
        margin: 0 7px;
    }

    footer .social {
        margin: 30px 0
    }

    footer p {
        font-size: 12px;
    }

    .news-area .row [class*="col-sm"] {
        padding: 0 10px;
    }

    .news-box {
        margin-top: 30px
    }

    .news-area {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .news-area .head .h2 {
        margin-bottom: 15px
    }

    .news-list {
        padding-top: 0;
    }

    .news-box .image {
        height: 200px
    }

    .news-box .title {
        font-size: 18px
    }

    .news-box .hover .title {
        font-size: 20px;
        line-height: 30px;
    }

    .news-box {
        border-radius: 20px
    }

    .news-box .image img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .news-box .date {
        font-size: 16px
    }

    .news-box .text .inner {
        height: 225px;
    }

    .news-box .hover {
        border-radius: 20px
    }

    .news-box .action {
        font-size: 17px
    }

    .back_btn {
        font-size: 20px
    }

    .news-content-area {
        padding-top: 40px
    }
    .news-content-area .content-img{
        padding-left: 0
    }
    .news-content-area .content-text {
    padding-right: 0px;
}
    .news-content-area .h1{
        font-size: 36px
    }
    .news-content-area .date{
        margin-top: 20px;
        font-size: 18px
    }
    .news-content .quotes{
        font-size: 36px;
        line-height: 120%;
        margin: 30px 0
    }
    .news-content .h3 {
    font-size: 26px;
    margin: 20px 0;
}
    .news-content .h4{
        font-size: 22px
    }


}

@media(max-width:767px) {
    .news-box .text .inner {
        margin-bottom: 20px;
        height: 178px
    }

    .news-box .image {
        height: 300px
    }

    .news-box {
        border-radius: 40px
    }

    .news-box .image img {
        border-radius: 40px
    }

    .news-box .hover {
        border-radius: 40px
    }

    .news-content a {
        word-break: break-all;
    }


}

@media(max-width:575px) {

    header .logo img {
        width: 143px;
    }

    header a.link {
        font-size: 16px;
    }

    .cbtn {
        padding: 10px 21px;
        font-size: 16px;
    }

    br {
        display: none;
    }


    .news-box .text .inner {
        height: auto;
    }

    .news-box .image {
        height: 350px
    }

    .news-content-area .content-img {
        padding-left: 0px;
        margin-bottom: 30px
    }

    .news-content br{display: block}


}


@media (max-width:575px) {

    body {
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .wrapper {
        position: relative;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
    }

}


.news-box .image {
    position: relative;
}

.news-box .image:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FF8585 0%, #A35C9F 109.43%);
    mix-blend-mode: multiply;
    z-index: 2;
    opacity: 0.95;
}

.news-box .image img {
  filter: grayscale(1);
}

div#pi_tracking_opt_in_div {
    display: none !important;
}
.CookieConsent button{
    min-width: 110px !important;
    background: #fff !important;
    color: #000 !important;
    font-size: 16px !important;
    box-shadow: 0 0px 15px rgba(255, 255, 255, 0) !important;
    font-weight: 700 !important;
    padding: 5px 25px !important;
    border-radius: 50px !important;
    margin: 5px !important
}
.CookieConsent button:hover{
    box-shadow: 0 0px 15px #ffffff70 !important
}
.CookieConsent > div {
    font-size: 15px !important;
    margin: 10px !important;
    padding-left: 60px !important
}
.CookieConsent > div:last-child{
    padding-left: 0 !important;
    padding-right: 60px !important
}
@media(max-width:767px){
    .CookieConsent > div {
        padding-left: 0px !important
    }
    .CookieConsent > div:last-child{
        padding-right: 0px !important
    }
    header {
        position: relative;
    }

    header.active {
        z-index: 22;
    }

    nav#menu {
        position: absolute;
        top: 99%;
        left: 0;
        width: 100%;
        display: block;
        z-index: 999;
        min-height: calc(100vh - 71px);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all;
    }

    nav#menu.active {
        opacity: 1;
        visibility: visible;
        transition: 0.3s all;
    }

    .mainmenu ul {
        text-align: left;
    }

    nav#menu,
    nav#menu > ul ul {
        overflow: hidden;
        max-height: 0;
        background: linear-gradient(180deg, #0B1E42 0%, #9E428F 100%);
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
    }

    nav#menu,
    nav#menu > ul ul {
        overflow: auto;
        padding: 50px 20px;
    }

    nav#menu > ul > li {
        display: block !important;
        margin: 0 !important;
        padding: 0
    }

    nav#menu ul ul,
    nav#menu ul ul ul {
        display: inherit;
        position: relative;
        left: auto;
        top: auto;
        padding: 0
    }

    nav#menu ul ul.sub-menu.active {
        visibility: visible;
        opacity: 1;
        max-height: 55em;
    }

    nav#menu > ul > li > a {
        display: block;
        position: relative;
        padding: 18px 0px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        font-size: 20px;
        margin-left: 0;
    }

    nav#menu > ul > li:last-child > a {
        border: none
    }

    .mainmenu ul li > ul {
        border: none
    }

    nav#menu li.has-submenu > a:after {
        border: none;
        content: '+';
        position: absolute;
        top: 10px;
        right: 15px;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: auto;
background: transparent;
    }

    .sub-menu::before {
        height: 90% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .sub-menu::after {
        display: none
    }

    nav#menu li.has-submenu > a.active:after {
        content: "-";
        font-size: 20px
    }

    nav#menu ul ul > li a {
        padding: 13px 0px;
        line-height: normal;
        color: #fff;
        font-size: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06)
    }

    ul.sub-menu {
        padding-left: 20px;
    }

    .header-toggle.active span:nth-child(2) {
        opacity: 0
    }

    .header-toggle.active span:nth-child(1) {
        top: 14px;
        transform: rotate(45deg);
    }

    .header-toggle.active span:nth-child(3) {
        top: 0px;
        transform: rotate(-45deg);
    }

    .header-toggle {
        display: block;
    }

    nav#menu > ul ul {
        background: transparent;
        box-shadow: none;
        width: 100%;
        padding-left: 15px
    }

    header {
        transition: all 0.4s
    }

    header.active {
        background: #0B1E42
    }

    header:after {
        content: "";
        background: #0B1E42;
        height: 300px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        visibility: hidden;
    }

    header .container {
        position: relative;
        z-index: 2;
    }

    header.active:after {
        visibility: visible;
    }
}
@media(max-width:575px){
    .CookieConsent{
        display: block !important;
        text-align: center !important
    }
}



@media(min-width: 992px) {

    .has-dropdown {
        position: relative;
    }
    
    .has-dropdown > a {
        padding-right: 17px;
        font-size: 20px;
        color: #fff;
        position: relative;
        left: 0;
    }
    
    .has-dropdown span {
        position: absolute;
        top: 10px;
        right: 0;
        width: 10px;
        height: 20px;
    }
    
    .has-dropdown span:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: 4px;
        left: 0px;
    }
    
    .has-dropdown ul {
        position: absolute;
        top: calc(100% - 5px);
        left: -30px;
        width: 200px;
        padding: 30px 0 15px;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all;
    }
    
    .has-dropdown:hover ul {
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
    
    .has-dropdown ul:after {
        content: "";
        background: #fff;
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        height: calc(100% - 20px);
        border-radius: 5px;
    }
    
    .has-dropdown ul:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 7px 7px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: 8px;
        left: 65px;
    }
    
    .has-dropdown ul li {
        position: relative;
        z-index: 2;
        margin: 0;
    }
    
    .has-dropdown ul li a {
        color: #000;
        display: table;
        padding: 0 15px;
        font-size: 17px;
    }
    
    .has-dropdown a:before {
        opacity: 0 !important;
    }
    
    }
    