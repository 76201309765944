@font-face {
    font-family: 'Harmonia Sans Pro';
    src: local('HarmoniaSansPro'),
      url('./fonts/HarmoniaSansPro/HarmoniaSansPro-Regular.woff2') format('woff2'),
        url('./fonts/HarmoniaSansPro/HarmoniaSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Harmonia Sans Pro Semi Bd';
    src: local('HarmoniaSansPro'),
      url('./fonts/HarmoniaSansPro/HarmoniaSansPro-SemiBd.woff2') format('woff2'),
        url('./fonts/HarmoniaSansPro/HarmoniaSansPro-SemiBd.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Harmonia Sans Pro';
    src: local('HarmoniaSansPro'),
      url('./fonts/HarmoniaSansPro/HarmoniaSansPro-Bold.woff2') format('woff2'),
        url('./fonts/HarmoniaSansPro/HarmoniaSansPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
